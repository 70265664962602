.referral-avatars {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: -3rem 0 2rem;

    @media (min-width: $screen-md) {
        gap: 2rem;
    }

    .avatar-inviter,
    .avatar-invitee {
        position: relative;

        img,
        .fallback-avatar-image {
            border: 3px solid #fff;
            border-radius: 50%;
            width: 8em;
            height: 8em;
            box-shadow: $box-shadow-base;
            background-color: $gray-ultralight;
        }

        img {
            object-fit: cover;
        }

        .fallback-avatar-image {
            .i-helmet {
                font-size: 7em;
                margin: -0.1em 0 0 0.05em;
                opacity: 0.35;
            }
        }

        .premium-badge-empty,
        .premium-badge-outline {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 3.3em;
            height: 4em;
        }

        @media (min-width: $screen-md) {
            font-size: 1.3rem;
        }
    }
}
